@import (reference) "styles/theme.less";

.contactInfoContainer {
  width: 100%;
  padding: 0px 25px;

  .label {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    color: var(--blue-ribbon);
    margin-top: 10px;
  }

  .contactInfoForm {
    input {
      height: 42px;
      border-radius: 3px;
      border: none;
      margin: 7px 0px;
      color: var(--blue-ribbon);
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
    }

    ::placeholder {
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
      color: var(--trolley-grey);
      text-transform: capitalize;
    }

    :global(.ant-select-selector) {
      margin: 5px 0px;
      padding: 0px 10px;
      height: 42px;
      border: none !important;

      :global(.ant-select-selection-item) {
        color: var(--blue-ribbon);
        font-family: "Barlow";
        font-weight: 500;
        font-size: 16px;
        padding-top: 6px;
      }
    }

    :global(.ant-form-item-with-help .ant-form-item-explain) {
      min-height: 0px;

      :global(.ant-form-item-explain-error) {
        font-size: 10px;
        position: absolute;
        margin-top: -9px;
        margin-right: 5px;
        text-align: right;
        width: 100%;
      }
    }

    :global(.ant-select-arrow) {
      margin-top: -25px;

      :global(.caretIcon) {
        height: 24px;
      }
    }

    .contactInfoSubmit {
      width: 149px;
      height: 45px;
      margin: 20px 0px;
    }
  }

  .dateOfBirthFormContainer {
    .contactInfoForm();

    .dateOfBirth {
      height: 325px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 35px;

      :global(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder) {
        color: var(--trolley-grey);
        font-size: 16px;
        font-weight: 500;
        padding-top: 6px;
      }
    }

    .error {
      font-family: "Poppins";
      color: var(--dark-pink);
    }

    :global(.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
        .ant-select-selector) {
      border: 1px solid var(--dark-pink) !important;
    }
  }

  .onboardingAddressFormContainer {
    .contactInfoForm();

    .addressForm {
      height: 325px;
      display: flex;
      flex-direction: column;
      :global(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder) {
        color: var(--trolley-grey);
        font-size: 16px;
        font-weight: 500;
        padding-top: 7px;
        text-transform: capitalize;
      }
      :global(.ant-select-selection-placeholder) {
        color: var(--trolley-grey);
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
      }
      :global(.ant-select-selector) {
        margin: 7px 0px;
      }
      :global(.ant-select-arrow) {
        margin-top: -27px;
      }
    }
  }

  .signUpPhonePrefix {
    :global(.ant-select-selector) {
      padding: 9px;
    }
  }
}

.dateOfBirthSignUpOption {
  :global(.ant-select-item-option-content) {
    color: var(--trolley-grey);
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: var(--light-grey-a25);

    :global(.ant-select-item-option-content) {
      color: var(--dark-blue);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;